import "./Experience.scss";

import React from "react";
import { motion } from "framer-motion";

import experienceBg from "../../assets/images/experience.jpg";
import img1 from "../../assets/images/img1.png";
import img2 from "../../assets/images/img2.jpg";

function Experience() {
  return (
    <div
      className="experience"
      id="Company"
      style={{ backgroundImage: `url(${experienceBg})` }}
    >
      <div className="experience-left">
        <motion.div
          initial={{ opacity: 0, x: -150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-image"
        >
          <img src={img1} alt="Build websites in Pune" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: -150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-text"
        >
          <div className="experience-left-text-number">5+</div>
          <div className="experience-left-text-name">Clients</div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-text"
        >
          <div className="experience-left-text-number">15+</div>
          <div className="experience-left-text-name">Technologies</div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 150 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-left-image"
        >
          <img src={img2} alt="Build mobile app in Pune" />
        </motion.div>
      </div>
      <div className="experience-right">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="experience-right-title"
        >
          Innovating in record time.
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          viewport={{ once: true }}
          className="experience-right-text"
        >
          Among millions of websites and apps, we craft ours to make them stand
          out. We build websites and applications that are fast in performance,
          cool to look at and incredible to own.
        </motion.div>
      </div>
    </div>
  );
}

export default Experience;
